import React from "react";
import { FaDiscord, FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { IconContext } from "react-icons";
import { useChain } from "@cosmos-kit/react";
import { chainName } from "../config/sei";
import { truncateAddress } from "../utils";

export default function NavbarItems() {
  const chain = useChain(chainName);

  return (
    <div>
      <div
        className="mx-6  items-center shadow-windows bg-neutral-300 border-2 px-5 py-2 font-bold text-blue-dark lg:flex cursor-pointer hover:border-white hover:bg-neutral-400 hover:text-white"
        onClick={chain.openView}
      >
        {chain.isWalletConnected
          ? truncateAddress(chain.address)
          : "Connect Wallet"}
      </div>
      <div className="my-20 flex gap-10">
        <IconContext.Provider value={{ className: "text-2xl" }}>
          <a href="https://t.me/SenSeiFi">
            <FaTelegramPlane />
          </a>
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "text-2xl" }}>
          <a href="https://twitter.com/SenSei_DeFi">
            <FaTwitter />
          </a>
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "text-2xl" }}>
          <a href="https://discord.gg/b6h3paCYy4">
            <FaDiscord />
          </a>
        </IconContext.Provider>
      </div>
    </div>
  );
}
